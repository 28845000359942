<template>
  <ValidationObserver
    ref="exportPdfModalRule"
  >
    <b-modal
      id="export-pdf-modal"
      ref="export-pdf-modal"
      :title="title"
      centered
      no-close-on-backdrop
      ok-title="Export"
      ok-only
      :ok-disabled="isLoading"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      :hide-header-close="isLoading"
      @show="setModalData"
      @hidden="resetModal"
      @ok.prevent="handleExportPdf"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form-group
          v-if="withExecute"
          label="Field with available application ID"
          class="mb-1"
        >
          <validation-provider
            #default="{ errors }"
            name="destination field"
            rules="required"
          >
            <v-select
              v-model="targetField"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="fieldsOptions"
              :reduce="option => option.value"
              :clearable="false"
              :placeholder="'Select Field'"
              :disabled="!fieldsOptions.length"
              :class="{'is-invalid': errors.length > 0 }"
              @input="handleTargetIdSelected"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Export mode"
        >
          <b-form-radio
            v-model="isSinglePdfFile"
            class="mb-1"
            name="exportMode"
            :value="true"
          >
            Export as a single PDF file
          </b-form-radio>
          <b-form-radio
            v-model="isSinglePdfFile"
            name="exportMode"
            :value="false"
          >
            Export as separate PDF files
          </b-form-radio>
        </b-form-group>

        <b-form-group
          v-if="withTCSelection"
          label="Tuition Card"
          class="mb-1 mt-2"
        >
          <v-select
            v-model="selectedTuitionCard"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="tuitionCardsList"
            :reduce="template => template.id"
            :placeholder="'Select Tuition Card'"
          />
        </b-form-group>

        <br>
        <b-form-group
          label="Tuition Card PDF Template"
          class="mb-3"
        >
          <validation-provider
            #default="{ errors }"
            name="tuition card template"
            rules="required"
          >
            <v-select
              v-model="selectedTemplate"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="templatesList"
              :clearable="false"
              :reduce="template => template.id"
              :placeholder="'Select Template'"
              :class="{'is-invalid': errors.length > 0 }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Export type"
        >
          <b-form-radio
            v-model="isSendByEmail"
            class="mb-1"
            name="exportType"
            :value="false"
          >
            Download file
          </b-form-radio>
          <b-form-radio
            v-model="isSendByEmail"
            name="exportType"
            :value="true"
          >
            Send file by email
          </b-form-radio>
        </b-form-group>
        <div
          v-if="isSendByEmail"
        >
          <hr>
          <h5
            class="text-center py-1"
          >
            This process may take a while. You will receive an email with the csv file when complete. Please enter email below.
          </h5>
          <b-form-group
            class="mb-2"
          >
            <!-- Email -->
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email to send"
                label-for="email"
              >
                <b-form-input
                  v-model="selectedEmail"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </div>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormGroup,
  BFormRadio,
  BFormInput,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useExportPdfModal from '@/views/components/fafsa-export-pdf-modal/useExportPdfModal'
import useReportDetails from '@/views/admin/reports-v2/reports/report-details/useReportDetails'

export default {
  components: {
    BFormInput,
    ValidationProvider,
    ValidationObserver,

    vSelect,
    BModal,
    BOverlay,
    BFormGroup,
    BFormRadio,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Export to PDF',
    },
    withTCSelection: {
      type: Boolean,
      default: false,
    },
    markedElements: {
      type: Array,
      required: true,
    },
    fieldsOptions: {
      type: [Array, Object],
      default: () => {},
    },
    executeData: {
      type: Object,
      default: () => {},
    },
    withExecute: {
      type: Boolean,
      default: () => false,
    },
    filterName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isSinglePdfFile: true,
      isSendByEmail: false,
      templatesList: [],
      tuitionCardsList: [],
      selectedTemplate: null,
      selectedTuitionCard: null,
      selectedEmail: null,
      selectedApplications: [],
      targetField: null,
      required,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
  },
  setup() {
    const {
      fetchTuitionCardTemplatesForSelect,
      fetchTuitionCardsForSelect,
    } = useExportPdfModal()

    const {
      fetchResultsByTargetField,
    } = useReportDetails()

    return {
      fetchResultsByTargetField,
      fetchTuitionCardTemplatesForSelect,
      fetchTuitionCardsForSelect,
    }
  },
  async created() {
    const queryParams = {
      program_id: this.programId,
    }
    this.templatesList = await this.fetchTuitionCardTemplatesForSelect(queryParams)
      .then(response => response.reduce((arr, item) => {
        arr.push({
          id: item.id,
          label: item.name,
          default_program_setting: item.default_program_setting,
          default_instance_setting: item.is_default,
        })
        return arr
      }, []))

    this.tuitionCardsList = await this.fetchTuitionCardsForSelect(queryParams)
      .then(response => response.reduce((arr, item) => {
        arr.push({ id: item.id, label: item.name, default_program_setting: item.default_program_setting })
        return arr
      }, []))
  },
  methods: {
    setModalData() {
      this.selectedTemplate = this.templatesList.find(item => item.default_program_setting)?.id ?? this.templatesList.find(item => item.default_instance_setting)?.id
      this.selectedTuitionCard = this.tuitionCardsList.find(item => item.default_program_setting)?.id
      this.selectedEmail = this.userData.email ?? ''
      this.isSendByEmail = false
    },
    handleExportPdf() {
      return new Promise((resolve, reject) => {
        this.$refs.exportPdfModalRule.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('handleExportPdf',
              this.isSinglePdfFile,
              this.selectedTemplate,
              this.isSendByEmail,
              this.selectedEmail,
              this.selectedTuitionCard,
              this.selectedApplications)
          } else {
            reject()
          }
        })
      })
    },
    resetModal() {
      this.isSinglePdfFile = true
      this.selectedApplications = []
      this.selectedTuitionCard = null
      this.selectedEmail = null
      this.selectedTemplate = null
    },
    async handleTargetIdSelected() {
      if (this.allElementsChecked) {
        const queryParams = {
          executeData: this.executeData,
          targetField: this.targetField,
        }
        if (this.programId) {
          queryParams.programId = this.programId
        }
        this.selectedApplications = await this.fetchResultsByTargetField(queryParams)
          .then(response => response.data)
      } else {
        this.selectedApplications = this.markedElements.map(element => element[this.targetField])
      }
    },
  },
}
</script>

<style>

</style>
