<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
              <b-button
                v-if="canCreateReport"
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-fafsa-report-details' }"
              >
                <span class="mr-25 align-middle text-light">Create Card</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refFafsaReportsListTable"
        :items="fetchReportsList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        sticky-header="75vh"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-sticky-head"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'admin-fafsa-report-details', query: { id: data.item.id } }"
          >{{ data.item.name }}</b-link>
        </template>

        <!-- Column: created at -->
        <template #cell(created_at)="data">
          {{ data.item.created_at | longDateTime }}
        </template>

        <template #cell(default_program_setting)="data">
          <feather-icon
            v-if="data.item.default_program_setting"
            id="set_as_deffault"
            icon="CheckCircleIcon"
            size="16"
            class="align-middle mr-1 text-success"
          />
          <feather-icon
            v-else
            icon="CircleIcon"
            size="16"
            class="align-middle mr-1 cursor-pointer"
            @click="updateDefaultForProgramTC(data.item.id)"
          />
          <b-popover
            target="set_as_deffault"
            triggers="hover"
            placement="topright"
            boundary="window"
          >
            <div class="d-flex align-items-center">
              <span class="ml-1">
                  The current program will use this card by default for generating Family Balance or if the TC will not be chosen
              </span>
            </div>
          </b-popover>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'admin-fafsa-report-details', query: { id: data.item.id } }"
            >
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canDeleteReport"
              @click="deleteReport(data.item.id)"
            >
              <span>Delete</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canExportPDF"
              @click="showExportToPdfModal(data.item)"
            >
              <span>Export PDF file</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canExportCSV"
              @click="exportBalanceReportCsv(data.item)"
            >
              <span>Export Balance Report</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>

    <export-pdf-modal
      :is-loading="isPdfLoading"
      @handleExportPdf="exportToPdf"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BButton, BLink, BPopover,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'

import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { filters } from '@core/mixins/filters'

import store from '@/store'
import storeModule from '@/views/admin/fafsa-reports/fafsa-reports-list/fafsaReportStoreModule'
import useFafsaRepostsList from '@/views/admin/fafsa-reports/fafsa-reports-list/useFafsaReportsList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import ExportPdfModal from '@/views/components/fafsa-export-pdf-modal/ExportPdfModal.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  name: 'FafsaReportsList',
  components: {
    BPopover,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,

    vSelect,
    TableSpinner,
    ExportPdfModal,
  },
  mixins: [downloadExportFile, filters],
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-fafsa'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchReportsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      isPdfLoading,
      selectedCard,
      refFafsaReportsListTable,

      deleteReport,
      exportPdf,
      exportBalanceReport,
      updateTuitionCardSettings,

      refetchData,
    } = useFafsaRepostsList(root)

    return {
      fetchReportsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      isPdfLoading,
      selectedCard,
      refFafsaReportsListTable,

      deleteReport,
      exportPdf,
      exportBalanceReport,
      updateTuitionCardSettings,

      refetchData,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
    canCreateReport() {
      return this.$can('create', permissionSubjects.FinancialReport)
    },
    canDeleteReport() {
      return this.$can('delete', permissionSubjects.FinancialReport)
    },
    canExportCSV() {
      return this.$can('export', permissionSubjects.ExportCSV)
    },
    canExportPDF() {
      return this.$can('export', permissionSubjects.ExportPDF)
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('fafsaPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('fafsaPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
    },
    showExportToPdfModal(item) {
      this.selectedCard = item
      this.$nextTick(() => {
        this.$bvModal.show('export-pdf-modal')
      })
    },
    async exportToPdf(isSinglePdfFile, templateId, isSendByEmail, selectedEmail) {
      this.isPdfLoading = true
      const queryParams = {
        is_single_pdf_file: isSinglePdfFile,
        template_id: templateId,
        send_by_email: false,
      }
      if (isSendByEmail && selectedEmail) {
        queryParams.send_by_email = true
        queryParams.selected_email = selectedEmail
      }
      await this.exportPdf(this.selectedCard.id, queryParams).then(response => {
        if (response.status === 200 && isSendByEmail === false) {
          this.downloadFile(response.data, `${this.selectedCard.name}(Tuition Cards)`, 'zip')
        }
      }).finally(() => {
        this.isPdfLoading = false
        this.$bvModal.hide('export-pdf-modal')
        this.selectedCard = null
      })
    },
    async exportBalanceReportCsv(item) {
      await this.exportBalanceReport(item.id).then(response => {
        if (response.status === 200) {
          this.downloadFile(response.data, `${item.name}(Balance Report)`, 'csv')
        }
      })
    },
    async updateDefaultForProgramTC(id) {
      const preparedData = {
        program_id: this.programId,
        entity_id: id,
        entity_type: 'fafsa',
      }
      await this.updateTuitionCardSettings(preparedData)
      await this.refetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {

  label {
    margin-bottom: 0;
  }
}

.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
